import React, { useEffect, useRef, useState } from "react";
import SelectInput from "../components/SelectInput";
import {
  useList3PLRidersQuery,
  useListRidersQuery,
} from "../services/slices/riderSlice";
import { useListTplQuery } from "../services/slices/tpl";
import { useNavigate } from "react-router-dom";
import { LoaderSpin } from "../components/Loader";
import { useDispatchOrderMutation } from "../services/slices/orderSlice";
import ModalContainer from "../components/ModalContainer";
import { Checkbox } from "flowbite-react";

interface Props {
  orders: String[];
  rider: number;
  userId: any;
  action: string;
  EnrouteTo3PL?: string;
  tpl?: number;
  userType: string | null;
}

const Scanner = () => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [shouldFetchTplId, setShouldFetchTplId] = useState(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [scanError, setScanError] = useState<boolean>(false);
  const [select3PL, setSelect3PL] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [scan, setScan] = useState<boolean>(false);
  const [dispatchData, setDispatchData] = useState<{ [key: string]: any }>({
    action: "",
    tpl: "",
    rider: "",
  });
  const [text, setText] = useState<string>("");
  const [scanErrorData, setScanErrorData] = useState<string>("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const { data: riderData, isLoading: isRiderLoading } = useListRidersQuery();
  const { data: tplData, isLoading: isTplLoading } = useListTplQuery(
    undefined,
    {
      skip: !shouldFetch,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );
  const [
    dispatchOrder,
    {
      data: orderData,
      isLoading: isOrderLoading,
      isSuccess: isOrderSuccess,
      isError: isOrderError,
    },
  ] = useDispatchOrderMutation();
  const [inputData, setInputData] = useState<String[]>([]);
  const [error, setError] = useState<{ [key: string]: any }>({
    tpl: "",
    action: "",
    rider: "",
  });
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const usertype = localStorage.getItem("usertype");
  const tplId = localStorage.getItem("tplId");

  const {
    data,
    isLoading,
  } = useList3PLRidersQuery(
    { TPLID: tplId },
    {
      skip: !shouldFetchTplId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const actionData = [
    {
      label: "Dispatch",
      value: "dispatch",
    },
    {
      label: "Picked Up",
      value: "picked_up",
    },
    {
      label: "Enroute To Last Mile Hub",
      value: "enroute",
    },
    {
      label: "Accepted At Last Mile Hub",
      value: "accepted_at_last_mile_hub",
    },
    {
      label: "Rejected At Last Mile Hub",
      value: "rejected_at_last_mile_hub",
    },

    {
      label: "Returned To Last Mile Hub",
      value: "returned_to_last_mile_hub",
    },
    {
      label: "In Return To First Mile Hub",
      value: "in_return_to_first_mile_hub",
    },
    {
      label: "In Return To Customer",
      value: "in_return_to_customer",
    },
    {
      label: "arrived_in_nigeria",
      value: "Arrived in Nigeria",
    },
    {
      label: "Returned To First Mile Hub",
      value: "return_to_first_mile_hub",
    },
    {
      label: "Exported",
      value: "exported",
    },
  ];

  useEffect(() => {
    const user = localStorage.getItem("usertype");
    const riderId = localStorage.getItem("riderId");
    if (user?.toLowerCase() === "fez_rider" && riderId) {
      setDispatchData((prev) => ({
        ...prev,
        rider: riderId,
      }));
    }
  }, []);

  const filteredActionData = actionData.filter((action) => {
    if (usertype === "tpl_rider") {
      return ["dispatch"].includes(action.value);
    }
    if (usertype === "tpl_admin") {
      return [
        "dispatch",
        "accepted_at_last_mile_hub",
        "rejected_at_last_mile_hub",
        "returned_to_last_mile_hub",
        "in_return_to_first_mile_hub",
      ].includes(action.value);
    }
    if (usertype === "fez_rider") {
      return ["dispatch", "enroute", "picked_up"].includes(action.value);
    }
    if (usertype === "fez_admin") {
      return [
        "dispatch",
        "enroute",
        "accepted_at_last_mile_hub",
        "arrived_in_nigeria",
        "rejected_at_last_mile_hub",
        "returned_to_last_mile_hub",
        "return_to_first_mile_hub",
        "in_return_to_customer",
        "exported",
        "in_return_to_first_mile_hub",
      ].includes(action.value);
    }
    return true;
  });

  useEffect(() => {
    if (scan && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [scan]);

  useEffect(() => {
    if (inputData.length === 200) {
      setScan(false);
    }
  }, [inputData]);

  // const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   if (scan) {
  //     let a;
  //     console.log("value===", e.target.value)

  //     a = "";
  //     setText("");
  //     a = e.target.value.trim();

  //     setText(a);
  //     console.log("text===", text)

  //     const truncatedText = text.slice(0, 12);
  //     if (
  //       !inputData.includes(truncatedText) &&
  //       text.length % 12 === 0 &&
  //       text.length !== 0
  //     ) {
  //       setInputData((prevInputData) => [...prevInputData, truncatedText]);
  //       setText("");
  //       setDuplicateError(false);
  //     } else if (inputData.includes(truncatedText)) {
  //       setBadData((prevInputData) => [...prevInputData, truncatedText]);
  //       setText("");
  //       setDuplicateError(true);
  //     }
  //   }
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    const value = e.target.value;
    setText(value.trim());
    debounceTimeout.current = setTimeout(() => {
      if (!inputData.includes(text) && text.length !== 0) {
        setInputData((prevInputData) => [...prevInputData, text]);
        setText("");
        setDuplicateError(false);
      } else if (inputData.includes(text)) {
        setText("");
        setDuplicateError(true);
      }
    }, 300);
  };

  const handleChange = (evt: any) => {
    const { name, value } = evt.target;
    if (name === "rider" && value !== "") {
      setError((prev) => ({
        ...prev,
        rider: "",
      }));
    }
    setScan(false);
    setDuplicateError(false);

    if (name === "action" && value !== "") {
      setError((prev) => ({
        ...prev,
        action: "",
        rider: "",
      }));
    }
    if (name === "tpl" && value !== "") {
      setError((prev) => ({
        ...prev,
        tpl: "",
      }));
    }
    setDispatchData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    if (inputData?.length > 0) {
      setScanErrorData("");
    }
    if (submit && inputData?.length > 0) {
      setScanErrorData("");
    } else if (submit && inputData?.length === 0) {
      setScanErrorData("Scan an order before submitting");
    }
  }, [submit, inputData]);

  function validateDispatch() {
    let newError = {};

    if (Object.keys(dispatchData).length === 0) {
      newError = {
        tpl: "",
        action: "Select an action",
        rider: "",
      };
    } else {
      if (
        dispatchData?.action?.toLowerCase() === "enroute" &&
        select3PL === true &&
        dispatchData.tpl === ""
      ) {
        newError = {
          ...newError,
          tpl: "Select a TPL",
        };
      } else if (
        dispatchData?.action?.toLowerCase() === "enroute" &&
        select3PL === true &&
        dispatchData.tpl !== ""
      ) {
        newError = {
          ...newError,
          tpl: "",
        };
      } else if (
        dispatchData?.action?.toLowerCase() !== "enroute" &&
        select3PL === false &&
        dispatchData.tpl === ""
      ) {
        newError = {
          ...newError,
          tpl: "",
        };
      }
      if (dispatchData?.action === "") {
        newError = {
          ...newError,
          action: "Select an action",
        };
      } else {
        newError = {
          ...newError,
          action: "",
        };
      }

      if (
        dispatchData?.rider === "" &&
        (dispatchData?.action === "enroute" ||
          dispatchData?.action === "dispatch" ||
          dispatchData?.action === "in_return_to_first_mile_hub" ||
          dispatchData?.action === "in_return_to_customer")
      ) {
        newError = {
          ...newError,
          rider: "Select a rider",
        };
      } else if (
        dispatchData?.rider === "" &&
        (dispatchData?.action !== "enroute" ||
          dispatchData?.action !== "dispatch" ||
          dispatchData?.action !== "in_return_to_first_mile_hub" ||
          dispatchData?.action !== "in_return_to_customer")
      ) {
        newError = {
          ...newError,
          rider: "",
        };
      } else {
        newError = {
          ...newError,
          rider: "",
        };
      }
    }

    setError(newError);
  }

  useEffect(() => {
    if (isOrderError) {
      setErrorModal(true);
    }
  }, [isOrderError]);

  const isValidAction =
    dispatchData?.action?.toLowerCase() === "enroute" ||
    dispatchData?.action?.toLowerCase() === "dispatch" ||
    dispatchData?.action?.toLowerCase() === "in_return_to_first_mile_hub" ||
    dispatchData?.action?.toLowerCase() === "in_return_to_customer";

  const isRiderRequired =
    dispatchData?.action?.toLowerCase() !== "enroute" &&
    dispatchData?.action?.toLowerCase() !== "dispatch" &&
    dispatchData?.action?.toLowerCase() !== "in_return_to_first_mile_hub" &&
    dispatchData?.action?.toLowerCase() !== "picked_up" &&
    dispatchData?.action?.toLowerCase() !== "accepted_at_last_mile_hub" &&
    dispatchData?.action?.toLowerCase() !== "rejected_at_last_mile_hub" &&
    dispatchData?.action?.toLowerCase() !== "exported" &&
    dispatchData?.action?.toLowerCase() !== "in_return_to_customer" &&
    dispatchData?.action?.toLowerCase() !== "arrived_in_nigeria";

  const handleSubmit = async () => {
    await validateDispatch();
    setSubmit(true);
    if (
      error.action === "" &&
      error.rider === "" &&
      inputData?.length > 0 &&
      dispatchData.action !== "" &&
      (isValidAction ? dispatchData.rider !== "" : true) &&
      (isRiderRequired ? dispatchData.rider === "" : true) &&
      ((dispatchData?.action?.toLowerCase() === "enroute" &&
        select3PL === true &&
        error.tpl === "" &&
        dispatchData.tpl !== "") ||
        (dispatchData?.action?.toLowerCase() === "enroute" &&
          select3PL === false &&
          (error.tpl === "" || error.tpl !== "" || error.tpl === undefined) &&
          (dispatchData.tpl === "" || dispatchData.tpl === undefined)) ||
        (dispatchData?.action?.toLowerCase() !== "enroute" &&
          select3PL === false &&
          (error.tpl === "" || error.tpl !== "")))
    ) {
      const body: Props = {
        orders: inputData,
        rider: dispatchData?.rider,
        userId: userId,
        action: dispatchData?.action,
        userType: usertype,
      };

      if (dispatchData?.action?.toLowerCase() === "enroute" && select3PL) {
        body.EnrouteTo3PL = "Y";
        body.tpl = dispatchData?.tpl;
      }

      dispatchOrder(body);
    } else {
      console.error(error);
    }
  };

  useEffect(() => {
    if (Boolean(isOrderSuccess)) {
      setSuccessModal(true);
    }
  }, [isOrderSuccess]);

  const removeData = (indexToRemove: number) => {
    setInputData((prevData) =>
      prevData.filter((_, idx) => idx !== indexToRemove)
    );
    setText("");
    setScanError(true);
    setScan(false);
  };

  return (
    <div className=" h-[100vh] mb-10  bg-white py-5 px-7 md:px-10">
      <div>
        <img
          src="/images/fez_logo.png"
          className="w-[100px] md:w-[150px] md:h-[100px] h-[70px]"
          alt=""
        />
      </div>
      <div className="flex mb-3  items-center justify-between">
        <div>
          <div className="pb-4 ml-5 flex space-x-2 items-center">
            <div className="cursor-pointer" onClick={() => {}}>
              <img src="/images/arrow-back.svg" className="w-7 h-7 " alt="" />
            </div>
            <h1 className="text-[17px] font-normal ">
              BarCode Scanner |
              <span className="text-textColorGreen"> Scan Page</span>
            </h1>
          </div>
        </div>
        <div className="pr-10  flex items-center space-x-3">
          {usertype?.toLowerCase() === "fez_admin" && (
            <div onClick={() => navigate(-1)}>
              <p className="text-textColorGreen underline cursor-pointer">
                Go back
              </p>
            </div>
          )}
          <button
            onClick={() => {
              localStorage.removeItem("barcode_token");
              localStorage.removeItem("userId");
              navigate("/login");
            }}
          >
            Logout
          </button>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-5  w-full justify-between">
        <div className="lg:w-[500px]">
          <div className="mt-5 space-y-5 lg:max-w-[500px]">
            <button
              className="border-[#289D17] border w-fit px-4  py-2 font-normal text-center rounded cursor-pointer text-[16px] text-[#289D17]"
              onClick={() => {
                setScan((prevScan) => !prevScan);
                setScanError(false);
              }}
            >
              {!scan ? "Scan" : "Stop Scanning"}
            </button>
            <div>
              <textarea
                required
                autoFocus
                className={`off-screen`}
                onChange={handleInputChange}
                placeholder="scan here"
                name="search-text"
                disabled={false}
                ref={textareaRef}
                value={text}
              />
            </div>
            <div>
              <SelectInput
                value={dispatchData?.action || ""}
                name={`action`}
                placeholder={"Select update to"}
                handleChange={(name, value) => {
                  setScanError(true);
                  setScan(false);
                  handleChange({ target: { name, value } });
                }}
                clearValue
                isRequired
                label="Select Update to"
                dropdownOptions={filteredActionData?.map((data) => ({
                  label: data.label,
                  value: data.value,
                }))}
              />
              <p className="text-xs text-[red]">{error.action}</p>
            </div>
            {(dispatchData?.action === "enroute" ||
              dispatchData?.action === "dispatch" ||
              dispatchData?.action === "in_return_to_first_mile_hub" ||
              dispatchData?.action === "in_return_to_customer") && (
              <div>
                <SelectInput
                  value={dispatchData?.rider ?? ""}
                  searchLoading={isRiderLoading || isLoading}
                  name={`rider`}
                  placeholder={"Select Rider"}
                  handleChange={(name, value) => {
                    setScanError(true);
                    setScan(false);
                    handleChange({ target: { name, value } });
                  }}
                  clearValue
                  isRequired={
                    dispatchData?.action === "enroute" ||
                    dispatchData?.action === "dispatch" ||
                    dispatchData?.action === "in_return_to_first_mile_hub" ||
                    dispatchData?.action === "in_return_to_customer"
                  }
                  label="Select Rider"
                  dropdownOptions={
                    usertype?.toLowerCase() === "tpl_admin" ||
                    usertype?.toLowerCase() === "tpl_rider"
                      ? data?.data?.map((rider: any) => ({
                          label: rider?.text,
                          value: rider?.id,
                        }))
                      : riderData?.data?.map((rider: any) => ({
                          label: rider?.ridersFullName,
                          value: rider?.ridersID,
                        }))
                  }
                />
                <p className="text-xs text-[red]">{error.rider}</p>
              </div>
            )}
            {dispatchData?.action?.toLowerCase() === "enroute" && (
              <div className="flex space-x-1.5 items-center">
                <Checkbox
                  className="border-btnColor checked:bg-btnColor focus:outline-none focus:ring-0"
                  onChange={(e) => {
                    setShouldFetch(true);
                    setSelect3PL(e.target.checked);
                  }}
                />
                <p className="text-sm ">Do you want to select 3PL?</p>
              </div>
            )}

            {dispatchData?.action?.toLowerCase() === "enroute" && select3PL && (
              <div>
                <SelectInput
                  value={dispatchData?.tpl}
                  searchLoading={isTplLoading}
                  name={`tpl`}
                  isRequired
                  clearValue={true}
                  placeholder={"Select TPL"}
                  handleChange={(name, value) => {
                    handleChange({ target: { name, value } });
                    setShouldFetchTplId(true);
                  }}
                  label="Select TPL"
                  dropdownOptions={tplData?.data?.map((tpl: any, idx) => ({
                    label: tpl?.companyName,
                    value: tpl?.id,
                  }))}
                />
                <p className="text-xs text-[red]">{error.tpl}</p>
              </div>
            )}
            <div className="pt-5 z-[1000]">
              <button
                onClick={handleSubmit}
                className="bg-[#289D17] w-full  py-2 font-normal text-center rounded cursor-pointer text-[20px] text-white"
                disabled={isOrderLoading}
              >
                {isOrderLoading ? <LoaderSpin color="#ffffff" /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[430px]">
          {scanError && (
            <p className="text-[red] text-center">
              Scanner paused. Click "Scan" to resume.
            </p>
          )}
          {duplicateError && (
            <p className="text-[red] text-center">
              Duplicate detected. Order already scanned
            </p>
          )}
          {scanErrorData !== "" && (
            <p className="text-[red] text-center">{scanErrorData}</p>
          )}
          <div className="border rounded-tr rounded-tl">
            <div className="pt-4 h-[60px] flex justify-between border-b px-4 rounded-tr rounded-tl   bg-[#289D17] shadow-sm">
              <p className="text-white">Scanned Data</p>
              <p className="text-white font-onestMedium">
                Count : {inputData?.length}
              </p>
            </div>
            <div className="py-4 px-4 h-[450px] overflow-y-scroll">
              {inputData?.map((data, idx) => (
                <div
                  key={idx}
                  className="bg-white flex mb-2 space-x-5 justify-between w-fit rounded py-2 px-2 shadow-md"
                >
                  <p className="">{data}</p>
                  <div
                    className="border rounded-[200px] p-1 cursor-pointer w-fit"
                    onClick={() => removeData(idx)}
                  >
                    <img
                      src="/images/remove-icon.png"
                      alt="warn-sign"
                      width={13}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        open={successModal}
        showCloseIcon={false}
        tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
      >
        {orderData?.data?.errors?.length > 0 ? (
          <div className="bg-white rounded-lg shadow-cardShadow relative">
            <div className="px-10 pt-8 ">
              <div className="flex flex-col items-center">
                <img
                  src="/images/warn-sign-1.webp"
                  alt="warn-sign"
                  width={70}
                />

                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                  Order Update Status
                </p>
                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                  An error occured while updating some orders. Please, see
                  details below
                </p>
              </div>
              <div className=" p-4 max-h-[38vh] overflow-y-scroll ">
                {orderData?.data?.errors?.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    className="bg-white  text-sm mt-2 shadow-cardDropdownShadow"
                  >
                    <div className="flex justify-between items-center font-rocGroteskMedium ">
                      <p className="text-black">Order No</p>
                      <p className="text-end text-slate-500">{item?.id}</p>
                    </div>
                    <div className="flex justify-end font-rocGroteskMedium ">
                      <p className="text-end text-[#FF3D1D]">{item?.message}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex pt-2 justify-between text-black items-center font-rocGroteskMedium">
                <div className="flex items-center space-x-1 ">
                  <p>Total Orders Not Updated</p>
                </div>
                <p className="text-end text-[#FF3D1D]">
                  {orderData?.data?.unverified}
                </p>
              </div>
              <div className="flex justify-between text-black items-center font-rocGroteskMedium">
                <div className="flex items-center space-x-1 ">
                  <p>Total Orders Updated</p>
                </div>
                <p className="text-end text-btnColor">
                  {orderData?.data?.verified}
                </p>
              </div>
            </div>
            <div className="w-full  px-8 py-8  border-slate-200 ">
              <button
                className="bg-[#289D17] w-full py-2 font-onestMedium text-center rounded cursor-pointer text-[18px] text-white"
                onClick={() => {
                  setDispatchData({
                    action: "",
                    tpl: "",
                    rider: "",
                  });
                  setSuccessModal(false);
                  setSubmit(false);
                  setInputData([]);
                  setText("");
                  setSelect3PL(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-cardShadow relative">
            <div className="px-10 pt-8 max-h-[70vh] overflow-y-scroll ">
              <div className="flex flex-col items-center">
                <img src="/images/success.png" alt="success-sign" width={70} />
                <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                  Order Updated
                  {/* {dispatchData?.action.toLowerCase() === "dispatch" &&
                    "Dispatch"}{" "}
                  {dispatchData?.action.toLowerCase() === "enroute" &&
                    "Dispatch"}{" "} */}
                  Successful
                </p>
                <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                  Your orders have been updated succesfully
                  {/* {dispatchData?.action.toLowerCase() === "dispatch" &&
                    "Your orders have been dispatched succesfully"}
                  {dispatchData?.action.toLowerCase() === "enroute" &&
                    "Your orders have been dispatched succesfully"} */}
                </p>
              </div>
            </div>
            <div className="w-full px-8 py-8  border-slate-200 ">
              <button
                className="bg-[#289D17] w-full py-2 font-onestMedium text-center rounded cursor-pointer text-[18px] text-white"
                onClick={() => {
                  setDispatchData({
                    action: "",
                    tpl: "",
                    rider: "",
                  });
                  setSuccessModal(false);
                  setInputData([]);
                  setText("");
                  setSubmit(false);
                  setSelect3PL(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </ModalContainer>
      <ModalContainer
        open={errorModal}
        showCloseIcon={false}
        tailwindClassName="w-[42%] max-lg:w-1/2 max-sm:w-[95%]"
      >
        <div className="bg-white rounded-lg shadow-cardShadow relative">
          <div className="px-10 pt-8 max-h-[70vh] overflow-y-scroll ">
            <div className="flex flex-col items-center">
              <img src="/images/error-icon.png" alt="success-sign" width={70} />

              <p className="text-xl mb-3 text-center mt-4 font-rocGroteskMedium text-g-75">
                Order Update Error
              </p>
              <p className="text-sm text-center font-rocGroteskMedium text-slate-500 max-w-[411px]">
                Something went wrong while updating
              </p>
            </div>
          </div>
          <div className="w-full px-8 py-8  border-slate-200 ">
            <button
              className="bg-[#289D17] w-full py-2 font-onestMedium text-center rounded cursor-pointer text-[18px] text-white"
              onClick={() => {
                setDispatchData({
                  action: "",
                  tpl: "",
                  rider: "",
                });
                setErrorModal(false);
                setSubmit(false);
                setInputData([]);
                setText("");
                setSelect3PL(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default Scanner;
