import { forwardRef } from "react";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";

interface BarCodeProps {
  barcodeData: any;
}

export const BarCode = forwardRef<HTMLDivElement, BarCodeProps>(
  ({ barcodeData }: BarCodeProps, ref) => {
    function shortenWord(word: string, maxLength: number) {
      if (word?.length <= maxLength) {
        return word;
      } else {
        return word?.slice(0, maxLength) + "...";
      }
    }

    return (
      <div ref={ref} className="font-onestMedium  relative">
        <div className=" w-[380px]">
          <div className="flex justify-between items-center">
            {barcodeData?.client?.businessType?.name.toLowerCase() ===
              "logistics" &&
              barcodeData?.client?.logo && (
                <img
                  src={barcodeData?.client?.logo}
                  className="w-[110px]  h-20"
                  alt="business_logo"
                />
              )}
            {barcodeData?.client?.businessType?.name.toLowerCase() ===
              "logistics" &&
              (barcodeData?.client?.logo === "" ||
                !barcodeData?.client?.logo) && (
                <p>{barcodeData?.client?.fullName}</p>
              )}

            {barcodeData?.client?.businessType?.name.toLowerCase() !==
              "logistics" && (
              <img
                src={"/images/fez_logo.png"}
                className="w-[110px]  h-20"
                alt="fez_logo"
              />
            )}
            <div className="text-end text-[#2c2e35]">
              <div className="font-bold ">
                {barcodeData?.client?.phone && (
                  <p>
                    {barcodeData?.client?.businessType?.name.toLowerCase() ===
                      "logistics" && barcodeData?.client?.phone}
                  </p>
                )}
                {(barcodeData?.client?.phone === "" ||
                  !barcodeData?.client?.phone) &&
                  barcodeData?.client?.businessType?.name.toLowerCase() !==
                    "logistics" && <p>090 6000 5049</p>}
              </div>
              {barcodeData?.client?.email && (
                <p>
                  {barcodeData?.client?.businessType?.name.toLowerCase() ===
                    "logistics" && barcodeData?.client?.email}
                </p>
              )}
              {(barcodeData?.client?.email === "" ||
                !barcodeData?.client?.email) &&
                barcodeData?.client?.businessType?.name.toLowerCase() !==
                  "logistics" && (
                  <>
                    <p>www.fezdelivery.co</p>
                    <p>support@fezdelivery.co</p>
                  </>
                )}
            </div>
          </div>
          <div className="border  px-3 text-[#2c2e35]   border-[#3b3d43] min-h-[140m]  pt-4">
            <div className=" pb-2">
            {barcodeData?.manifest?.orderNo && (
              <Barcode value={barcodeData.manifest.orderNo} height={45} />
            )}
              {/* <QRCode
                fgColor="#2c2e35"
                value={`https://fezdelivery.co/${barcodeData?.client?.clientId}/${barcodeData?.manifest?.orderNo}`}
                className="w-[150px] h-[150px]"
              /> */}
            </div>
            <div className="relative">
              <h1 className="text-[25px] font-bold text-[#2c2e35] flex space-x-2 items-center  pb-2  border-dashed  border-b-[1.5px] border-b-[#5b5f61] ">
                <p>{barcodeData?.manifest?.pickUpState || "N/A"}</p> <p> - </p>
                <p> {barcodeData?.manifest?.dropOffState || "N/A"}</p>
              </h1>
              {(barcodeData?.manifest?.requestType?.toLowerCase() === "local" ||
                barcodeData?.manifest?.requestType?.toLowerCase() ===
                  undefined ||
                !barcodeData?.manifest?.requestType ||
                barcodeData?.manifest?.requestType?.toLowerCase() === "") && (
                <p className="text-sm flex space-x-2 items-center text-[#2c2e35] mt-1">
                  <span>
                    {" "}
                    {barcodeData?.manifest?.PickUpHub} <span></span>-
                  </span>
                  <span>{barcodeData?.manifest?.DropOffHub}</span>
                </p>
              )}
              <div
                className={`h-[300px] w-[370px]  ${
                  barcodeData?.client?.businessType?.name.toLowerCase() !==
                    "logistics" && "bg-hero-pattern bg-contain bg-no-repeat"
                }`}
              >
                <div className="mt-3  space-y-2  ">
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Order ID:{" "}
                    <span className="font-normal text-[15px]">
                      {barcodeData?.manifest?.orderNo || "N/A"}
                    </span>
                  </h1>
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Recipient Name:{" "}
                    <span className="font-normal text-[15px]">
                      {barcodeData?.manifest?.recipientName || "N/A"}
                    </span>
                  </h1>
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Recipient Phone number:{" "}
                    <span className="font-normal text-[15px]">
                      {barcodeData?.manifest?.recipientPhone || "N/A"}
                    </span>
                  </h1>
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Recipient Address:{" "}
                    <span className="font-normal text-[15px]">
                      {barcodeData?.manifest?.recipientAddress || "N/A"}
                    </span>
                  </h1>
                  {barcodeData?.manifest?.displayZone && (
                    <h1 className="font-bold text-[15px] text-[#2c2e35]">
                      Drop-off Zone:{" "}
                      <span className="font-normal text-[15px]">
                        {barcodeData?.manifest?.dropOffZone || "N/A"}
                      </span>
                    </h1>
                  )}

                

                  <hr className=" w-[95%] border-dashed  border-b-[1.5px] border-b-[#5b5f61]" />
                </div>
                <div className="mt-3 space-y-3 pb-10 ">
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Content description:{" "}
                    <span className="font-normal text-[15px]">
                      {shortenWord(barcodeData?.manifest?.description, 30)}
                    </span>
                  </h1>
                  {Boolean(Number(barcodeData?.manifest?.cod_amount)) && (
                    <h1 className="font-bold text-[15px] text-[#2c2e35]">
                      POD Value:{" "}
                      <span className="font-normal text-[15px]">
                        ₦
                        {barcodeData?.manifest.cod_amount}
                      </span>
                    </h1>
                  )}
                  <h1 className="font-bold text-[15px] text-[#2c2e35]">
                    Sender Name:{" "}
                    <span className="font-normal text-[15px]">
                      {barcodeData?.client?.fullName.toLowerCase() ===
                      "fez website"
                        ? barcodeData?.thirdPartySendersName
                        : barcodeData?.client?.fullName}
                    </span>
                  </h1>
                  {Boolean(barcodeData?.manifest?.fragile) && (
                    <h1 className="font-bold text-[15px] text-[#2c2e35]">
                      Fragile:{" "}
                      <span className="font-normal text-[15px]">Yes</span>
                    </h1>
                  )}
                </div>
              </div>
            </div>
            {/* {barcodeData?.manifest?.orderNo && (
              <Barcode value={barcodeData.manifest.orderNo} height={45} />
            )} */}
          </div>
        </div>
      </div>
    );
  }
);
