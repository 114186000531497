import React from "react";
import { PreTag } from "./PreTag";

const PrintTemplate = ({ detail }: any) => {
  return (
    <div>
      <div>
        <PreTag
          barcodeData={detail}
        />
        <br />
      </div>
      <div className="break-after-page">&nbsp;</div>
    </div>
  );
};

export const ComponentToPrintPreTag = React.forwardRef((props: any, ref: any) => {
  const { details } = props;
  const printingPages =
    details &&
    details.length &&
    details.map((detail: any) => (
      <PrintTemplate key={detail.id} detail={detail} />
    ));

  return (
    <div ref={ref}>
      {printingPages}
    </div>
  );
});
