import { forwardRef } from "react";
import Barcode from "react-barcode";

interface BarCodeProps {
  barcodeData: any;
}

export const PreTag = forwardRef<HTMLDivElement, BarCodeProps>(
  ({ barcodeData }: BarCodeProps, ref) => {
    return (
      <div ref={ref} className="font-onestMedium  relative">
        <div className=" w-[50mm] border  h-[25mm] border-[#2c2e35]">
          <div className=" px-3 text-[#2c2e35]    pt-4">
            <Barcode
              value={barcodeData?.manifest?.orderNo}
              width={1}
              height={40}
              margin={0}
              displayValue={false}
              fontSize={12}
            />
          </div>
        </div>
      </div>
    );
  }
);
