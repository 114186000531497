import { useEffect, useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Webcam from "react-webcam";
import { DashboardModalAction } from "../components/Modal";
import { OrderTable } from "../components/Table";
import {
  useGetRejectionReasonsQuery,
  useSearchOrderMutation,
  useVerifyOrderMutation,
} from "../services/slices/orderSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { WebcamCapture } from "../components/Camera";
import { TableLoaderTwo } from "../components/Loader";
import { BarCode } from "../components/BarcodeGen";
import { SwitchBox } from "../components/Switch";

interface OrderStatus {
  [key: number]: string;
}

interface Props {
  searchData: any;
  setSearchData: any;
  setCurrentPage: any;
  currentPage: any;
  onClickBack: any;
  onLogOut: any;
}

const Order = ({
  searchData,
  setSearchData,
  setCurrentPage,
  currentPage,
  onClickBack,
  onLogOut,
}: Props) => {
  const [checkVerifyOrder, setCheckVerifyOrder] = useState(false);
  const [rejectOrder, setRejectOrder] = useState(false);
  const [continueRejection, setContinueRejection] = useState(false);
  const [rejectionGivenData, setRejectionGivenData] = useState(false);
  const [verifyGivenData, setVerifyGivenData] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [dataId, setDataId] = useState("");
  const [reasonId, setReasonId] = useState("");
  const [verifyContent, setVerifyContent] = useState("");
  const [barcodeData, setBarcodeData] = useState<any>([]);
  const [capturedImages, setCapturedImages] = useState<any>([]);
  const [capturedImagesTwo, setCapturedImagesTwo] = useState<any>([]);
  const [hasInitialPicture, setHasInitialPicture] = useState(false);
  const [addingMorePictures, setAddingMorePictures] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OrderStatus>({});
  const [toggle, setToggle] = useState(false);

  const webcamRef = useRef<Webcam>(null) as React.RefObject<any>;

  const urlParams = new URLSearchParams(window.location.search);
  const searchText = urlParams.get("searchText");
  const endDate = urlParams.get("endDate");
  const startDate = urlParams.get("startDate");
  const page = urlParams.get("page");
  const client = urlParams.get("client");
  const rider = urlParams.get("rider");
  const status = urlParams.get("status");
  const dateType = urlParams.get("dateType");
  const searchType = urlParams.get("searchType");

  const params = {
    searchText: searchText,
    client,
    rider,
    startDate,
    endDate,
    page,
    status,
    dateType,
    searchType
  };

  const updatedParams = {
    searchText: params.searchText || "",
    client: params.client || "",
    rider: params.rider || "",
    startDate: params.startDate || "",
    endDate: params.endDate || "",
    page: params.page || "",
    orderStatus: params.status || "",
    dateType:params.dateType ||"",
    searchType:params.searchType ||""
  };
  const [
    verifyOrder,
    {
      isLoading: isVerifyOrderLoading,
      isSuccess: isVerifyOrderSucess,
    },
  ] = useVerifyOrderMutation();
  const [
    searchOrder,
    { data: orderData, isLoading: isOrderLoading, isSuccess: isOrderSuccess },
  ] = useSearchOrderMutation();
  const { data: reasonData } = useGetRejectionReasonsQuery();

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const onClose = useCallback(() => {
    setCapturedImages([]);
    setCapturedImagesTwo([]);
    setHasInitialPicture(false);
  }, []);
  const onPrint = useCallback(async () => {
    try {
      await setOpenPrint(false);
      await onClose();
      await setCameraOn(false);
      setIsPrinting(true);
      handlePrint();
      setIsPrinting(false);
    } catch (error) {
      console.log({});
      setIsPrinting(false);
    }
  }, [handlePrint, onClose]);
 
  const searchPaginatedOrder = useCallback(async () => {
    try {
      await searchOrder({
        search: searchText,
        page: page,
        startCreatedDate: startDate==="undefined"?"":startDate,
        endCreatedDate: endDate==="undefined"?"":endDate,
        clientId: client,
        riderId: rider,
        orderStatus: status,
        searchDateType: dateType,
        searchType
      }).unwrap();
    } catch (e) {
      console.log({});
    }
  }, [searchText,startDate, endDate, client , rider, status, dateType, searchType, page, searchOrder]);

  const capture = useCallback(async () => {
    if (webcamRef && webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc !== null) {
        const parts = imageSrc?.split(",");
        const mimeType = parts[0]?.split(":")[1].split(";")[0];
        const fileExtension = mimeType.split("/")[1];
        const base64Data = parts[1];

        if (!hasInitialPicture) {
          setHasInitialPicture(true);
          setCapturedImagesTwo((prevImages: any) => [...prevImages, imageSrc]);
          setCapturedImages((prevImages: any) => [
            ...prevImages,
            { extension: fileExtension, image: base64Data },
          ]);
        } else if (addingMorePictures) {
          setCapturedImages((prevImages: any) => [
            ...prevImages,
            { extension: fileExtension, image: base64Data },
          ]);
        }
      }
      webcamRef?.current?.video?.pause();
    }
  }, [webcamRef, hasInitialPicture, addingMorePictures]);

  const handleRemoveImages = (index: any) => {
    setCapturedImages((prevImages: any) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    setCapturedImagesTwo((prevImages: any) => {
      const updatedImagesTwo = [...prevImages];
      updatedImagesTwo.splice(index, 1);
      return updatedImagesTwo;
    });
  };


  useEffect(() => {
    if (isVerifyOrderSucess && verifyContent === "reject") {
      setContinueRejection(false);
      setRejectionGivenData(true);
    } else if (isVerifyOrderSucess && verifyContent === "verify") {
      setOrderStatus((prevStatus) => ({
        ...prevStatus,
        [barcodeData?.id]: "accepted",
      }));
      if (toggle) {
        onPrint();
      }
      onClose();
      setCameraOn(false);
    } else {
      setRejectionGivenData(false);
      setVerifyGivenData(false);
    }
  }, [isVerifyOrderSucess, verifyContent, barcodeData, onPrint, toggle, onClose]);

  const navigate = useNavigate();


  const onReject = async () => {
    setVerifyContent("reject");
    try {
      // Perform the login logic
      await verifyOrder({
        id: Number(dataId),
        status: false,
        rejectionId: Number(reasonId),
      });
    } catch (e) {
      console.log({});
    }
  };

  const onVerify = async () => {
    setVerifyContent("verify");
    try {
      // Perform the login logic
      await verifyOrder({
        id: Number(barcodeData?.id),
        status: true,
        medias: capturedImages,
      });
      onClose();
      setCameraOn(false);
    } catch (e) {
      console.log({});
    }
  };

  useEffect(() => {
    return () => {
      setIsPrinting(false);
    };
  }, []);



  const clickPrint = (e: any) => {
    setBarcodeData(e);
    setOpenPrint(true);
  };
  const handlePageClick = (data: { selected: number }) => {
    const selectedPage = data.selected + 1;

    setCurrentPage(selectedPage);
    if ("URLSearchParams" in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("page", selectedPage.toString());
      window.location.search = searchParams.toString();
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageParam = searchParams.get("page");

    if (pageParam) {
      const parsedPage = parseInt(pageParam);

      if (!isNaN(parsedPage)) {
        setCurrentPage(parsedPage);
      }
    }
  }, [setCurrentPage]);

  useEffect(() => {
    searchPaginatedOrder();
  
  }, [searchPaginatedOrder]);

  useEffect(() => {
    if (isOrderSuccess) {
      setSearchData(orderData);
    }
  }, [orderData, isOrderSuccess, setSearchData]);

  const onCheckVerifyOrder = () => {
    setCheckVerifyOrder(false);
    onVerify();
  };

  const onClickPrintPage = () => {
    navigate({
      pathname: "/print",
      search: `?${createSearchParams(updatedParams)}`,
    });
  };

  return (
    <div className=" h-[100vh] mb-10  bg-white py-5 px-7 md:px-10">
      <div className="flex items-center justify-between">
        <div>
          <div>
            <img
              src="/images/fez_logo.png"
              className="w-[100px] md:w-[150px] md:h-[100px] h-[70px]"
              alt=""
            />
          </div>
          <div className="pb-4 ml-5 flex space-x-2 items-center">
            <div className="cursor-pointer" onClick={onClickBack}>
              <img src="/images/arrow-back.svg" className="w-7 h-7 " alt="" />
            </div>
            <h1 className="text-[17px] font-normal ">
              BarCode Verification |
              <span className="text-textColorGreen"> Order Page</span>
            </h1>
          </div>
        </div>

        <div className="pr-10 flex space-x-3">
          <div onClick={() => navigate("/scan-qr-code")}>
            <p className="text-textColorGreen underline cursor-pointer">
              Scan Page
            </p>
          </div>
          <button onClick={onClickPrintPage}>PrintPage</button>
          <button onClick={onLogOut}>Logout</button>
        </div>
      </div>
      <div className="pr-2">
        <SwitchBox toggle={toggle} setToggle={setToggle} />
      </div>
      {!isOrderLoading ? (
        <>
          {" "}
          {searchData && searchData?.data?.data?.length > 0 ? (
            <div className="w-full  border-blackAlpha-880 rounded-xl shadow-md ">
              <OrderTable
                orderStatus={orderStatus}
                data={searchData?.data?.data}
                onReject={(e: any) => {
                  setRejectOrder(true);
                  setDataId(e);
                }}
                onVerify={(e: any) => {
                  setBarcodeData(e);
                  setCheckVerifyOrder(true);
                }}
                onPrint={(e: any) => {
                  clickPrint(e);
                }}
                isPrinting={isPrinting}
                loading={isOrderLoading}
              />
              <div className="px-8 py-5 my-5 w-full flex-col justify-center  md:flex-row flex md:justify-between">
                <h1 className=" text-[#333333] flex md:text-start text-center mb-3 items-center text-[13px] ">
                  {`Showing ${searchData?.data?.meta?.from} - ${searchData?.data?.meta?.to} of ${searchData?.data?.meta?.total} results`}
                </h1>
                <div className="flex md:justify-normal  justify-center space-x-3">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={searchData?.data?.meta?.last_page}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"paginationLink"}
                    nextLinkClassName={"paginationLink"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-[16px] text-textColorBlack text-center my-32">
                No data!!!
              </p>
            </div>
          )}{" "}
        </>
      ) : (
        <TableLoaderTwo />
      )}
      {checkVerifyOrder && process.env.REACT_APP_CAMERA_ON === "true" && (
        <DashboardModalAction
          contentTitle="Verify order"
          content="Are you sure you want to verify this order’s details? Capture the product"
          btnContent="Proceed"
          btnContentTwo="Cancel"
          imageStr="/images/greenModal.png"
          verify
          onClose={() => {
            setCheckVerifyOrder(false);
            setCameraOn(true);
          }}
          onCloseTwo={() => setCheckVerifyOrder(false)}
        />
      )}
      {checkVerifyOrder && process.env.REACT_APP_CAMERA_ON === "false" && (
        <DashboardModalAction
          contentTitle="Verify order"
          content="Are you sure you want to verify this order’s details?"
          btnContent="Proceed"
          btnContentTwo="Cancel"
          imageStr="/images/greenModal.png"
          verify
          onClose={onCheckVerifyOrder}
          onCloseTwo={() => setCheckVerifyOrder(false)}
        />
      )}
      {rejectOrder && (
        <DashboardModalAction
          contentTitle="Reject order"
          content="Are you sure you want to reject this order’s details?"
          btnContent="Proceed"
          btnContentTwo="Cancel"
          imageStr="/images/redModal.png"
          verify
          onCloseTwo={() => setRejectOrder(false)}
          onClose={() => {
            setRejectOrder(false);
            setContinueRejection(true);
          }}
        />
      )}
      {continueRejection && (
        <DashboardModalAction
          contentTitle="Reject order"
          content="Select a reason to reject this order’s details?"
          btnContent="Reject"
          btnContentTwo="Cancel"
          imageStr="/images/redModal.png"
          verify
          reasons
          onSelectChange={(e: any) => setReasonId(e.target.value)}
          data={reasonData?.data}
          isLoading={isVerifyOrderLoading}
          onCloseTwo={() => {
            setContinueRejection(false);
          }}
          onClose={onReject}
        />
      )}
      {openPrint && (
        <DashboardModalAction
          contentTitle="Print order"
          btnContent="Print"
          btnContentTwo="Cancel"
          imageStr="/images/print.png"
          verify
          content="Do you want to print the order?"
          onCloseTwo={() => {
            setOpenPrint(false);
          }}
          onClose={() => {
            setOpenPrint(false);
            onPrint();
          }}
        />
      )}
      {verifyGivenData && (
        <DashboardModalAction
          contentTitle="Verify order"
          content="The order’s details have been verified"
          btnContent="Cancel"
          success
          imageStr="/images/greenModal.png"
          verify
          onCloseTwo={() => {
            setVerifyGivenData(false);
          }}
          onClose={() => {
            setVerifyGivenData(false);
          }}
        />
      )}
      {rejectionGivenData && (
        <DashboardModalAction
          contentTitle="Reject order"
          content="The order’s details have been rejected"
          btnContent="Cancel"
          success
          imageStr="/images/redModal.png"
          verify
          onCloseTwo={() => {
            setOrderStatus((prevStatus) => ({
              ...prevStatus,
              [dataId]: "rejected",
            }));
            setRejectionGivenData(false);
          }}
          onClose={() => {
            setOrderStatus((prevStatus) => ({
              ...prevStatus,
              [dataId]: "rejected",
            }));
            setRejectionGivenData(false);
          }}
        />
      )}
      {cameraOn && (
        <WebcamCapture
          onVerify={onVerify}
          webcamRef={webcamRef}
          capture={capture}
          onClose={onClose}
          isLoading={isVerifyOrderLoading}
          capturedImages={capturedImagesTwo}
          close={() => setCameraOn(false)}
          hasInitialPicture={hasInitialPicture}
          addingMorePictures={addingMorePictures}
          setAddingMorePictures={setAddingMorePictures}
          setHasInitialPicture={setHasInitialPicture}
          handleRemoveImages={handleRemoveImages}
        />
      )}

      <div className="hidden">
        <BarCode
          barcodeData={barcodeData}
          ref={componentRef}
        />
      </div>
    </div>
  );
};

export default Order;
