import { useEffect, useState } from "react";
import { TableLoader } from "./Loader";

type OrderStatus = {
  [key: number]: string;
};

export type DataProps = {
  id: number;
  orderNo?: string;
  date?: string;
  client: {
    fullName?: string;
    address?: string;
    phone?: string;
  };
  pickUpRider: {
    fullName: string;
  };
  dropOffRider: {
    fullName: string;
  };
  orderCreationDate?: string;
  recipientName?: string;
  recipientAddress?: string;
  recipientPhone?: string;
  itemDescription?: string;
  cod_amount?: string;
  orderVerified?: string;
  status?: string;
};
type TableProps = {
  data: Array<DataProps>;
  className?: String;
  onVerify?: any;
  onReject?: any;
  onPrint?: any;
  loading?: boolean;
  showRejected?: boolean;
  isPrinting?: boolean;
  printCheck?: boolean;
  selectAll?: boolean;
  printPage?: boolean;
  setSelectAll?: any;
  orderVerify?: string;
  orderCreationDate?: string;
  selectedRows?: number[];
  items?: any;
  handleRowSelect?: any;
  orderStatus?: OrderStatus;
  onSelectRows?: (selectedData: DataProps[]) => void;
};

export const OrderTable = ({
  data,
  loading,
  onPrint,
  onReject,
  onVerify,
  printCheck,
  handleRowSelect,
  orderStatus = {},
  setSelectAll,
  selectAll = false,
  printPage,
  onSelectRows,
}: TableProps) => {
  const [selectedLocalStorageRow, setSelectedLocalStorageRow] = useState<
    number[]
  >([]);
  useEffect(() => {
    let selectedData: DataProps[] = [];
    if (selectAll && data && data.length) {
      selectedData = data;
      if (onSelectRows) {
        onSelectRows(selectedData);
      }
    }
  }, [selectAll, data, onSelectRows]);

  useEffect(() => {
    const localStorageData: DataProps[] = JSON.parse(
      localStorage.getItem("selectedItem") || "[]"
    );
    if (localStorageData.length === 0) {
      localStorage.removeItem("selectedItem");
    }
    setSelectedLocalStorageRow(localStorageData.map((data) => data.id));
  }, [handleRowSelect]);

  return (
    <div className="flex w-full flex-col  mt-5">
      <div className="-my-2 overflow-x-auto  w-full  ">
        <div className="inline-block  min-w-full py-2 align-middle">
          <div className="  bg-white  min-w-full ">
            <table className=" w-full  mb-6 font-inter  overflow-x-scroll">
              <thead className="bg-[#f5f5f5] rounded-[12px] ">
                <tr className="">
                  {printCheck && (
                    <th className="px-4">
                      <input
                        type="checkbox"
                        className="rounded-[4px] checked:bg-btnColor border-black  checked:border-0 focus:border-black focus:border-1  focus:ring-0"
                        checked={selectAll}
                        onChange={() => setSelectAll(!selectAll)}
                      />
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Order number
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Sender Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Sender Address
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Sender Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Recipient Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333]  font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Recipient Address
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4  text-[#333333] font-bold whitespace-nowrap tracking-wider text-left text-textColor text-[14px]"
                  >
                    Recipient Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Pick-up Rider Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 whitespace-nowrap text-[#333333] font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Drop-off Rider Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4  text-[#333333] font-bold tracking-wider whitespace-nowrap text-left text-textColor text-[14px]"
                  >
                    Item Description
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4  text-[#333333] font-bold tracking-wider whitespace-nowrap text-left text-textColor text-[14px]"
                  >
                    Pay on delivery
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4  text-[#333333] font-bold tracking-wider text-left whitespace-nowrap text-textColor text-[14px]"
                  >
                    Order Creation Date
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4  text-[#333333] font-bold tracking-wider text-left whitespace-nowrap text-textColor text-[14px]"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-[#333333] whitespace-nowrap font-bold tracking-wider text-left text-textColor text-[14px]"
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {data?.map((tableData) => (
                  <tr
                    className="transition-all text-[#5B5B5B] border-b border-b-[#faf7f7]"
                    key={tableData?.id}
                  >
                    {printCheck && (
                      <td className="px-4">
                        <input
                          type="checkbox"
                          className="rounded-[4px] checked:bg-btnColor border-black  checked:border-0 focus:border-black focus:border-1  focus:ring-0"
                          checked={
                            selectAll ||
                            selectedLocalStorageRow.includes(tableData.id)
                          }
                          onChange={() => handleRowSelect(tableData)}
                        />
                      </td>
                    )}
                    <td className="px-4 py-4 text-start  text-[14px] text-primary whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.orderNo}
                    </td>
                    <td className="px-4 py-4 text-start  text-[14px] text-primary whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.client.fullName}
                    </td>
                    <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.client.address}
                    </td>
                    <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.client.phone}
                    </td>
                    <td className="px-4 py-4 text-start  text-[14px] text-primary whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.recipientName}
                    </td>
                    <td className="px-4 text-start  py-4 text-[14px] whitespace-nowrap text-textColor ">
                      {loading ? <TableLoader /> : tableData?.recipientAddress}
                    </td>
                    <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                      {loading ? <TableLoader /> : tableData?.recipientPhone}
                    </td>
                    <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                      {loading ? (
                        <TableLoader />
                      ) : (
                        tableData?.pickUpRider?.fullName
                      )}
                    </td>
                    <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                      {loading ? (
                        <TableLoader />
                      ) : (
                        tableData?.dropOffRider?.fullName
                      )}
                    </td>
                    <td className="px-4 text-start max-w-[600px] py-4 text-[14px] whitespace-nowrap text-textColor whitespace-wrap">
                      {loading ? <TableLoader /> : tableData?.itemDescription}
                    </td>
                    <td className="px-4 text-start max-w-[600px] py-4 text-[14px] whitespace-nowrap text-textColor whitespace-wrap">
                      {loading ? (
                        <TableLoader />
                      ) : (
                        `  ₦ ${tableData?.cod_amount}`
                      )}
                    </td>
                    <td className="px-4 text-start max-w-[600px] py-4 text-[14px] text-textColor whitespace-wrap">
                      {loading ? (
                        <TableLoader />
                      ) : (
                        new Date(
                          tableData?.orderCreationDate || ""
                        ).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })
                      )}
                    </td>
                    <td className="px-4 text-start w-[700px] py-4 text-[14px] whitespace-nowrap text-textColor whitespace-wrap">
                      {loading ? <TableLoader /> : tableData?.status}
                    </td>
                    {loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        {!printPage ? (
                          <td className="px-4 text-start py-4 text-[14px] text-textColor whitespace-nowrap">
                            {tableData.orderVerified === "pending" &&
                              orderStatus?.[tableData.id] !== "accepted" &&
                              orderStatus?.[tableData.id] !== "rejected" && (
                                <div className="flex space-x-4">
                                  <button
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-btnColor hover:bg-[#289D17] rounded-lg"
                                    onClick={() => onVerify(tableData)}
                                    disabled={false}
                                    name="Verify"
                                  >
                                    Verify
                                  </button>
                                  <button
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-textColorGreen border border-btnColor bg-transparent rounded-lg hover:bg-[#289D17] hover:text-white"
                                    onClick={() => onReject(tableData?.id)}
                                    disabled={false}
                                    name="Reject"
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}{" "}
                            {(tableData.orderVerified === "accepted" ||
                              orderStatus?.[tableData.id] === "accepted") && (
                              <div className="">
                                <button
                                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-btnColor hover:bg-[#289D17] rounded-lg"
                                  onClick={() => onPrint(tableData)}
                                  disabled={false}
                                  name="Print"
                                >
                                  Print
                                </button>
                              </div>
                            )}
                            {(tableData.orderVerified === "rejected" ||
                              orderStatus?.[tableData.id] === "rejected") && (
                              <div className="">
                                <div className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-[#fb4242] hover:bg-[#fb4242] rounded-lg">
                                  Rejected
                                </div>
                              </div>
                            )}
                          </td>
                        ) : (
                          <div className="py-4">
                            <button
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-btnColor hover:bg-[#289D17] rounded-lg"
                              onClick={() => onPrint(tableData)}
                              disabled={false}
                              name="Print"
                            >
                              Print
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
